import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';
import SwiperCore, { A11y, Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import queryString from 'qs';
import { ArrowLeftIcon, ArrowRightIcon, StarIcon } from '@components/Icons';
import CommuteTime from '@components/Listing/Search/CommuteTime';
import Price from '@components/Text/Price';
import ImageService from '@services/ImageService';
import GeneralStatic from '@utilities/static/GeneralStatic';
import ListingEditStatic from '@utilities/static/ListingEditStatic';
import StringUtility from '@utilities/StringUtility';

const buildListingViewUrl = (filters, listing) => {
  let path = `/rooms/${listing.get('slug_url')}`;

  if (filters && filters.get('start') && filters.get('end')) {
    const query = queryString.stringify({
      start: filters.get('start'),
      end: filters.get('end'),
    });

    path += '?' + query;
  }

  return path;
};

const ListingTile = ({ commuteTime, index, filters, listing, onHoverChange, type }) => {
  const url = buildListingViewUrl(filters, listing);
  SwiperCore.use([A11y, Lazy, Navigation]);
  const swiperSettings = {
    a11y: true,
    className: 'content h--100p',
    lazy: {
      checkInView: true,
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
    modules: [A11y, Lazy, Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    preloadImages: false,
    spaceBetween: 0,
    speed: GeneralStatic.transitionDefault,
  };

  return (
    <div
      className="tile"
      itemProp="itemListElement"
      itemScope
      itemType="http://schema.org/ListItem"
      onMouseEnter={onHoverChange.bind(this, listing.get('slug_url'), true)}
      onMouseLeave={onHoverChange.bind(this, listing.get('slug_url'), false)}
    >
      <meta content={listing.get('title')} itemProp="name" />
      <meta content={index + 1} itemProp="position" />
      <meta content={`${process.env.ESPRESSO_BASE}/rooms/${listing.get('slug_url')}`} itemProp="url" />
      <div className="listing-description-wrapper">
        <div className="listing-description">
          { listing.get('description') }
        </div>
      </div>
      <div className="three-two overflow-hidden cursor-pointer no-outline b--rounded-md">
        <Swiper {...swiperSettings}>
          { listing.get('photos').map((photo, i) => {
            const src = type === 'elasticsearch' ? photo : photo.get('url_350');

            return (
              <SwiperSlide key={`photo-${i}`}>
                <Link
                  className="block w--100p h--100p hover-none"
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                >

                  <img
                    alt={`Picture ${i + 1} of ${listing.get('bedrooms_total')} bedroom ${ListingEditStatic.tab1.propertyTypeMap[listing.get('property_type')]} in ${listing.getIn(['address', 'city'])}`}
                    className="swiper-lazy cover w--100p mih--100p transform-center-center"
                    data-src={src || ImageService.listingPlaceholder}
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />

                </Link>
              </SwiperSlide>
            );
          })}
          <div className="swiper-button-next swiper-button-white hover-grow--sm">
            <div className="w--100p h--100p bg--white b--round box-shadow--top p-around--small layout-row layout-align-center-center">
              <ArrowRightIcon className="icon--black icon--16 icon-stroke--xs" />
            </div>
          </div>
          <div className="swiper-button-prev swiper-button-white hover-grow--sm">
            <div className="w--100p h--100p bg--white b--round box-shadow--top p-around--small layout-row layout-align-center-center">
              <ArrowLeftIcon className="icon--black icon--16 icon-stroke--xs" />
            </div>
          </div>
        </Swiper>
      </div>
      <div className="p-top--medium-gt-xs p-top--xx-small relative">
        <div className="overflow-hidden">
          <div className="gray-0 layout-row layout-align-start-center">
            <h6 className="white-space-nowrap">{ listing.get('accommodates') } { StringUtility.pluralize('bed', listing.get('accommodates')) }{ listing.get('is_price_per_tenant') ? ' available' : '' }</h6>
            <h6 className="white-space-nowrap">, { listing.get('bedrooms_for_rent') } bedroom</h6>
            <h6 className="white-space-nowrap dot-separator">, { listing.get('bathrooms') } bathroom</h6>
            <h6 className="truncate">{ ListingEditStatic.tab1.propertyTypeMap[listing.get('property_type')] }</h6>
          </div>
          <Link
            className="inline-block mw--100p black hover-none m-top--xx-small"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >

            <h4 className="truncate">{ listing.get('title') }</h4>

          </Link>
          <div className="layout-row layout-align-start-center layout-wrap">
            <p className={`meta m-top--none truncate ${ commuteTime ? 'dot-separator' : ''}`}>
              <span>{ listing.get('is_price_per_tenant') && listing.get('accommodates') > 1 && <span>From </span> }<Price amount={listing.get('average_price')} isRounded />/month • </span>
              { listing.getIn(['address', 'city']) }, { listing.getIn(['address', 'state']) }
            </p>
            <CommuteTime commute={commuteTime} />
          </div>
          { listing.get('rating') > 0 && (
            <div className="h--24 meta m-top--xx-small layout-row layout-align-start-center">
              <div className="layout-row layout-align-start-center">
                <StarIcon className="icon--18 yellow inline-block m-right--x-small" />
                <span>{ (+listing.get('rating')).toFixed(2) } of 5.00</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ListingTile.propTypes = {
  // Required
  filters: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  listing: PropTypes.object.isRequired,
  // Optional
  commuteTime: PropTypes.object,
  onHoverChange: PropTypes.func,
  type: PropTypes.string,
};

ListingTile.defaultProps = {
  commuteTime: null,
  onHoverChange: () => {},
  type: 'default',
};

const mapStateToProps = (state) => ({
  filters: state.get('Search').get('filters'),
});

export default connect(mapStateToProps, {})(ListingTile);
