import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Core/Button';
import { StarIcon } from '@components/Icons';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paused: false,
    };
  }

  handlePlayPause = () => {
    this.setState({
      paused: !this.state.paused,
    });
  }

  render() {
    const { headerText, items, wrapperClassName } =  this.props;
    const { paused } =  this.state;

    return (
      <section className={wrapperClassName}>
        <h2 className="ta-center p-horizontal--large p-horizontal--xx-large-gt-xs">{headerText}</h2>
        <div className="scroller m-top--xx-large m-top--xxx-large-gt-xs">
          <div className={`scroll-track ${paused ? 'paused' : ''}`}>
            { items.map((item, index) => (
              <div className="scroll bg--white b-around--xs b--gray-2 b--rounded m-right--medium p-around--large" key={index}>
                <div className="h--24 meta m-right--x-small m-top--xx-small layout-row layout-align-start-center">
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow" />
                </div>
                <p className="m-top--medium">{ item.quote }</p>
                <p className="meta"><b>{ item.name }</b>, { item.title }</p>
              </div>
            ))}
            { items.map((item, index) => (
              <div className="scroll bg--white b-around--xs b--gray-2 b--rounded m-right--medium p-around--large" key={index}>
                <div className="h--24 meta m-right--x-small m-top--xx-small layout-row layout-align-start-center">
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow m-right--xx-small" />
                  <StarIcon className="icon--24 yellow" />
                </div>
                <p className="m-top--medium">{ item.quote }</p>
                <p className="meta"><b>{ item.name }</b>, { item.title }</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mw--md h--32 layout-row layout-align-end-start m-horizontal--auto m-top--x-large p-horizontal--large p-horizontal--x-large-gt-xs">
          <Button
            ariaLabel="Play/Pause carousel scroll"
            className="button-icon square--24"
            onClick={this.handlePlayPause}
          >
            <div className={`play-button ${paused ? '' : 'active'}`}>
              <div className="arrow arrow-left" />
              <div className="arrow arrow-right" />
            </div>
          </Button>
        </div>
      </section>
    );
  }
}

Testimonials.propTypes = {
  // Required
  items: PropTypes.array.isRequired,

  // Optional
  headerText: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

Testimonials.defaultProps = {
  headerText: 'Renters and hosts love Kopa',
  wrapperClassName: 'p-top--xx-large-lt-sm p-top--xxx-large-gt-xs',
};

export default Testimonials;
