export default [
  {
    name: 'San Francisco',
    slug: 'san-francisco',
    state: {
      name: 'CA',
      slug: 'california',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/san-francisco`,
    imgAlt: 'San Francisco Golden Gate Bridge',
  }, {
    name: 'New York City',
    slug: 'new-york',
    state: {
      name: 'NY',
      slug: 'new-york',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/new-york-city`,
    imgAlt: 'Central Park in downtown Manhattan New York City',
  }, {
    name: 'Seattle',
    slug: 'seattle',
    state: {
      name: 'WA',
      slug: 'washington',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/seattle`,
    imgAlt: 'Space Needle through glass museum in Seattle',
  }, {
    name: 'Philadelphia',
    slug: 'philadelphia',
    state: {
      name: 'PA',
      slug: 'pennsylvania',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/philadelphia`,
    imgAlt: '... in philadelphia',
  }, {
    name: 'San Jose',
    slug: 'san-jose',
    state: {
      name: 'CA',
      slug: 'california',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/san-jose`,
    imgAlt: 'Palm tree city center in San Jose',
  }, {
    name: 'Boston',
    slug: 'boston',
    state: {
      name: 'MA',
      slug: 'massachusetts',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/boston`,
    imgAlt: '... in Boston',
  }, {
    name: 'Nashville',
    slug: 'nashville',
    state: {
      name: 'TN',
      slug: 'tennessee',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/nashville`,
    imgAlt: '... in Nashville',
  }, {
    name: 'Los Angeles',
    slug: 'los-angeles',
    state: {
      name: 'CA',
      slug: 'california',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/los-angeles`,
    imgAlt: '... in Los Angeles',
  }, {
    name: 'Dallas',
    slug: 'dallas',
    state: {
      name: 'TX',
      slug: 'texas',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/dallas`,
    imgAlt: '... in Dallas',
  }, {
    name: 'Denver',
    slug: 'denver',
    state: {
      name: 'CO',
      slug: 'colorado',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/denver`,
    imgAlt: '... in Denver',
  }, {
    name: 'Washington, D.C.',
    slug: 'washington',
    state: {
      name: null,
      slug: 'district-of-columbia',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/washington`,
    imgAlt: '... in Washington, D.C.',
  }, {
    name: 'Chicago',
    slug: 'chicago',
    state: {
      name: 'IL',
      slug: 'illinois',
    },
    country: 'USA',
    img: `${process.env.STATIC_IMAGES_URL}/cities/chicago`,
    imgAlt: 'Downtown Chicago',
  },
];
