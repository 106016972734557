import React from 'react';
import PropTypes from 'prop-types';
import PaymentUtility from '@utilities/PaymentUtility';

const Price = ({ amount, isHeading, isRounded, wrapperClassName }) => {
  const signValue = amount < 0 ? '-' : '';
  if (isHeading) {
    return (
      <div className="layout-row layout-align-end-start">
        <h3 className="m-top--x-small">{ signValue }$</h3>
        <h2 className="h1">{ PaymentUtility.format(Math.abs(amount), isRounded) }</h2>
      </div>
    );
  }
  
  return (
    <span className={wrapperClassName}>
      { signValue }${ PaymentUtility.format(Math.abs(amount), isRounded) }
    </span>
  );
};

Price.propTypes = {
  // Required
  amount: PropTypes.number.isRequired,
  // Optional
  isHeading: PropTypes.bool,
  isRounded: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

Price.defaultProps = {
  isHeading: false,
  isRounded: false,
  wrapperClassName: '',
};

export default Price;
