import React from 'react';
import PropTypes from 'prop-types';
import { CheckmarkIcon } from '@components/Icons';

const Checklist = ({ itemClassName, items, wrapperClassName }) => {
  return (
    <div className={wrapperClassName}>
      { items.map(item => (
        <div className={`m-top--small layout-row layout-align-start-start ${itemClassName}`} key={item}>
          <div className="w--24 h--24 b-around--sm b--inherit b--round m-top--xxx-small m-right--small layout-row layout-align-center-center flex-no-shrink">
            <CheckmarkIcon className="icon--16" />
          </div>
          <p className="m-top--none">{ item }</p>
        </div>
      ))}
    </div>
  );
};

Checklist.propTypes = {
  // Required
  items: PropTypes.array.isRequired,
  // Optional
  itemClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

Checklist.defaultProps = {
  itemClassName: '',
  wrapperClassName: '',
};

export default Checklist;
