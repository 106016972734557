import React from 'react';
import LandingPageLogoList from '@components/LandingPages/LandingPageLogoList';

// Note: the mw--100p is so the image alt tag does not overflow on mobile.
const RECENT_PRESS_DATA = [{
  imgAlt: 'Yahoo Finance hosts flocking to medium-term housing',
  imgSrc: `${process.env.STATIC_IMAGES_URL}/press/yahoo-finance-logo-color@2x.png`,
  link: 'https://finance.yahoo.com/news/airbnb-hosts-turn-to-longer-term-rental-income-during-coronavirus-outbreak-204246569.html',
  width: 136,
  height: 49,
}, {
  imgAlt: 'TechCrunch Kopa medium-term housing and housemate marketplace',
  imgSrc: `${process.env.STATIC_IMAGES_URL}/press/techcrunch-logo-color@2x.png`,
  link: 'https://www.youtube.com/watch?v=0MzlF0ss7v4',
  width: 224,
  height: 31,
}, {
  imgAlt: 'Y Combinator intern to Kopa founder',
  imgSrc: `${process.env.STATIC_IMAGES_URL}/press/y-combinator-logo-color@2x.png`,
  link: 'https://blog.ycombinator.com/intern-1-to-yc-founder-an-interview-with-jack-forbes-of-kopa-yc-w19',
  width: 203,
  height: 43,
}, {
  imgAlt: 'Rent Responsibly Kopa for Good',
  imgSrc: `${process.env.STATIC_IMAGES_URL}/press/rent-responsibly-logo-color@2x.png`,
  link: 'https://www.rentresponsibly.org/flexible-rental-platform-kopa-unites-property-managers-and-hosts-to-provide-housing-in-coronavirus-crisis',
  width: 110,
  height: 48,
}, {
  imgAlt: 'Landlord Gurus Kopa for Good',
  imgSrc: `${process.env.STATIC_IMAGES_URL}/press/landlord-guru-logo-color@2x.png`,
  link: 'https://landlordgurus.com/covid-19-how-landlords-can-help',
  width: 218,
  height: 18,
}];

const RecentPress = () => (
  <LandingPageLogoList
    heading="In the News"
    logos={RECENT_PRESS_DATA}
    wrapperClassName="mw--md m-horizontal--auto p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs p-horizontal--large p-horizontal--x-large-gt-xs"
  />
);

export default RecentPress;
