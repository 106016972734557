import React from 'react';
import PropTypes from 'prop-types';
import ListingTile from '@components/Listing/Search/ListingTile';

const ListingList = ({ listings, type }) => (
  <div className="tiles-container layout-gt-xs-row layout-wrap" itemProp="mainEntityOfPage" itemScope itemType="https://schema.org/CollectionPage">
    { listings.map((listing, i) => (
      <div className="listing-tile m-top--x-large flex-100 flex-sm-50 flex-gt-sm-25 w--100p" key={i}>
        <ListingTile index={i} listing={listing} type={type} />
      </div>
    ))}
  </div>
);

ListingList.propTypes = {
  // Required
  listings: PropTypes.object.isRequired,
  // Optional
  type: PropTypes.string,
};

ListingList.defaultProps = {
  type: 'default',
};

export default ListingList;
