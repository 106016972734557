import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import PropTypes from 'prop-types';
import ListingList from '@components/Listing/ListingList';

const FeaturedListings = ({ description, featuredListings, link, listings, title, type, wrapperClassName }) => {
  const displayedListings = featuredListings && featuredListings.size > 0 ? featuredListings : listings;
  if (!displayedListings || (displayedListings && displayedListings.size < 1)) return null;

  return (
    <section className={`${wrapperClassName} mw--md m-horizontal--auto p-vertical--x-large p-vertical--xx-large-gt-xs p-horizontal--large p-horizontal--x-large-gt-xs`}>
      <h2>{ title }</h2>
      { description && (
        <p className="mw--780 m-top--small">{ description }</p>
      )}
      { link && (
        <Link className="inline-block underline chevron-link m-top--small" href={link}>
          
          Browse all rentals
          
        </Link>
      )}
      <ListingList listings={displayedListings} type={type} />
    </section>
  );
};

FeaturedListings.propTypes = {
  featuredListings: PropTypes.object.isRequired,
  // Optional
  description: PropTypes.string,
  link: PropTypes.string,
  listings: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

FeaturedListings.defaultProps = {
  description: null,
  link: null,
  listings: null,
  title: 'Featured rentals',
  type: 'default',
  wrapperClassName: '',
};

const mapStateToProps = (state) => ({
  featuredListings: state.get('ListingSearch').get('featuredListings'),
});

export default connect(mapStateToProps, {})(FeaturedListings);
