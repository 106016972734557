import React from 'react';
import PropTypes from 'prop-types';
import { TravelBikeIcon, TravelCarIcon, TravelTransitIcon, TravelWalkIcon } from '@components/Icons';

const TravelIconComponentsMap = {
  bike: TravelBikeIcon,
  car: TravelCarIcon,
  transit: TravelTransitIcon,
  walk: TravelWalkIcon,
};

const getTravelIcon = (travelMode) => {
  const TravelIconComponent = TravelIconComponentsMap[travelMode];

  if (TravelIconComponent) {
    return <TravelIconComponent className="icon--black icon--16" />;
  } else {
    return <span />;  // Should not happen but don't want the entire page to fail if the icon isn't located
  }
};

const CommuteTime = ({ commute }) => {
  if (!commute) return null;

  return (
    <p className="m-top--none layout-row layout-align-start-center">
      { getTravelIcon(commute.get('travel_mode')) }
      <span className="meta m-left--xx-small">{ commute.get('duration') }</span>
    </p>
  );
};

CommuteTime.propTypes = {
  commute: PropTypes.object,
};

CommuteTime.defaultProps = {
  commute: null,
};

export default CommuteTime;
